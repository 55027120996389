import React, { useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { JsonValue, JsonArray } from 'react-use-websocket/dist/lib/types';
import '../App.css';
import {
    websocketURL,
    MIN_ROLLING_WAIT_TIME_MS,
    MAX_ROLLING_WAIT_TIME_MS,
    ROLLING_DICE_INTERVAL_MS,
    RESET_GAME_WAIT_DURATION_MS,
} from '../constants';
import { getRandomInt } from '../random';
import {
    Response,
    EventType,
} from '../types';

import Menu from './Menu';
import Lobby from './Lobby';
import Scoreboard from './Scoreboard';
import GameBody from './GameBody';

interface GameProps {
    name: string
}

function Game(props: GameProps) {

    // const [messageHistory, setMessageHistory] = useState([])
    const [gameJoined, setGameJoined] = useState(false)
    const [gameStarted, setGameStarted] = useState(false)
    const [joinKey, setJoinKey] = useState('')
    const [currentTurn, setCurrentTurn] = useState(0)

    const [players, setPlayers] = useState<Array<string>>([])
    const [scores, setScores] = useState<Array<number>>([])
    const [turnScore, setTurnScore] = useState<number>(0)

    const [dice, setDice] = useState<Array<number>>([])
    const [selectedDice, setSelectedDice] = useState(Array<boolean>(6).fill(false))
    const [savedDice, setSavedDice] = useState<Set<number>>(new Set())
    const [canPlayerRoll, setCanPlayerRoll] = useState(true)
    const [forkled, setForkled] = useState(false)
    const [areThereRemainingDice, setAreThereRemainingDice] = useState(false)

    const [winner, setWinner] = useState<string | null>(null)
    const [disconnected, setDisconnected] = useState(false)

    const {
        sendMessage,
        sendJsonMessage,
        lastMessage,
        lastJsonMessage,
        readyState,
        getWebSocket,
      } = useWebSocket(websocketURL, {
        onOpen: () => console.log('websocket connection opened with: ' + websocketURL),
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
    });

    React.useEffect(() => {
        if (lastMessage !== null) {
            console.log("message from server: " + lastMessage.data)
            // setMessageHistory((prev) => prev.concat(lastMessage.data))
            
            // Check if the message is JSON, ignore if not
            let message: Response;
            try {
                message = JSON.parse(lastMessage.data)
            } catch (error) {
                return
            }

            if (message.type === EventType.Init) {
                setJoinKey(message.joinKey!)
                setPlayers([message.name])
                setGameJoined(true)
                console.log(message.name + " has created a game!")
            } else if (message.type === EventType.Join) {
                console.log(message.name + " has joined the game!")
                setPlayers(message.allPlayers!)
                setGameJoined(true)
            } else if (message.type === EventType.StartGame) {
                console.log(message.name + " has started the game!")
                // Set the initial game state
                setCanPlayerRoll(true)
                setSavedDice(new Set())
                setDice([])
                setTurnScore(0)
                setForkled(false)
                setCurrentTurn(0)
                setScores(Array(players.length).fill(0))

                setGameStarted(true)
            } else if (message.type === EventType.Roll) {
                let numNonZeroDice = dice.filter(d => d !== 0).length
                let numDiceRolling = numNonZeroDice === 0 || savedDice.size === 0 ? 6 : numNonZeroDice
                let rollingId = window.setInterval(() => {
                    setDice(Array(numDiceRolling).fill(undefined).map(() => Math.round(Math.random() * 5) + 1))
                }, ROLLING_DICE_INTERVAL_MS)
                // Add a delay to the rolling to simulate a longer roll
                setTimeout(() => {
                    clearInterval(rollingId)
                    setCanPlayerRoll(false)
                    setForkled(message.forkled!)
                    setDice(message.roll!)
                }, getRandomInt(MIN_ROLLING_WAIT_TIME_MS, MAX_ROLLING_WAIT_TIME_MS))
            } else if (message.type === EventType.SaveDice) {
                if (message.remainingDice != null && message.remainingDice.length > 0) {
                    console.log("There are remaining dice: " + message.remainingDice)
                    // If there were any dice saved that didn't score, this was an invalid save
                    setAreThereRemainingDice(true)
                    setTimeout(() => {
                        setAreThereRemainingDice(false)
                    }, 500)
                } else {
                    let saved_indices = new Set(message.savedIndices)
                    console.log(message.name + " has saved dice indices: " + Array.from(saved_indices.values()))
                    
                    // If all dice have been saved, show no dice until next roll
                    if (saved_indices.size === 0) {
                        setDice([])
                    } else {
                        let dice_copy = [...dice]
                        for (let i = 0; i < dice_copy.length; i++) {
                            if (saved_indices.has(i)) {
                                dice_copy[i] = 0
                            }
                        }
                        setDice(dice_copy)
                    }
                    setSavedDice(saved_indices)
                    setTurnScore(message.turnPoints!)
                    setCanPlayerRoll(true)
                }
            } else if (message.type === EventType.EndTurn) {
                console.log(message.name + " has ended their turn!")
                // Add their total points in
                let scores_copy = [...scores]
                scores_copy[currentTurn] = message.totalPoints!
                setScores(scores_copy)

                // Reset the turn state
                setCanPlayerRoll(true)
                setSavedDice(new Set())
                setDice([])
                setTurnScore(0)
                setForkled(false)
                setCurrentTurn(message.currentTurn ?? (currentTurn + 1) % players.length)
            } else if (message.type === EventType.Win) {
                console.log(message.name + " has won the game!")
                // Update the final points
                let scores_copy = [...scores]
                scores_copy[currentTurn] = message.totalPoints!
                setScores(scores_copy)

                setWinner(message.name)
                // Reset the game after displaying the winner
                setTimeout(() => {
                    setWinner(null)
                    setJoinKey('')
                    setGameJoined(false)
                    setGameStarted(false)
                }, RESET_GAME_WAIT_DURATION_MS)
            } else if (message.type === EventType.Disconnect) {
                console.log(message.name + " has disconnected")
                setDisconnected(true)
            } else if (message.type === EventType.Remove) {
                console.log(message.name + " has disconnected from the game")
                setCurrentTurn(currentTurn % players.length)
                setPlayers(players.filter(playerName => playerName !== message.name))
            } else if (message.type === EventType.Error) {
                console.error(message.error)
            }
        }
    }, [lastMessage/*, setMessageHistory*/]);

    // Convenience function to always send message with the player name
    const sendMessageWithName = (message: JsonValue) => {
        sendJsonMessage({...message, "name": props.name})
    }

    const isItMyTurn = (): boolean => {
        return players[currentTurn] === props.name 
    }

    const createGame = () => {
        sendMessageWithName({"type": EventType.Init})
    }

    const joinGame = () => {
        if (joinKey === "") {
            return
        }
        sendMessageWithName({"type": EventType.Join, "joinKey": joinKey.toLocaleLowerCase()})
    }

    const startGame = () => {
        sendMessageWithName({"type": EventType.StartGame})
    }

    const roll = () => {
        if (!isItMyTurn()) return
        sendMessageWithName({"type": EventType.Roll})
    }

    const saveDice = () => {
        if (!isItMyTurn()) return
        let saved_indices = new Set(savedDice)
        for (let i = 0; i < selectedDice.length; i++) {
            if (selectedDice[i] === true) {
                saved_indices.add(i)
            }
        }
        sendMessageWithName({"type": EventType.SaveDice, "savedIndices": Array.from(saved_indices) as unknown as JsonArray})
        setSelectedDice([])
    }

    const endTurn = () => {
        if (!isItMyTurn()) return
        sendMessageWithName({"type": EventType.EndTurn})
        setSelectedDice([...selectedDice].map(() => false))
    }

    return (
        <>
            {/* If no game has been joined yet */}
            {!gameJoined && <>
                <Menu joinKey={joinKey} setJoinKey={setJoinKey} createGame={createGame} joinGame={joinGame}/>
            </>}
            {/* If a game has been joined but not started yet */}
            {gameJoined && !gameStarted && !disconnected && <>
                <Lobby players={players} joinKey={joinKey} startGame={startGame}/>
            </>}
            {/* If a game has been joined and has started */}
            {gameJoined && gameStarted && !winner && !disconnected && <>
                <Scoreboard players={players} scores={scores} turn={currentTurn}/>
                <GameBody 
                    players={players} 
                    currentTurn={currentTurn}
                    turnScore={turnScore}
                    dice={dice}
                    selectedDice={selectedDice}
                    canPlayerRoll={canPlayerRoll}
                    forkled={forkled}
                    areThereRemainingDice={areThereRemainingDice}
                    isItMyTurn={isItMyTurn}
                    setSelectedDice={setSelectedDice}
                    roll={roll}
                    saveDice={saveDice}
                    endTurn={endTurn}
                />
            </>}
            {winner && <>
                <div className="WinnerModal">
                    <div className="WinnerModalContent">{winner} has won the game with {scores[currentTurn]} points!</div>
                </div>
            </>}
            {disconnected && <>
                <p>You have been disconnected</p>
            </>}
        </>
    );
}

export default Game;