import Dice from './Dice'

interface GameBodyProps {
    players: string[],
    currentTurn: number,
    turnScore: number,
    dice: Array<number>,
    selectedDice: Array<boolean>,
    canPlayerRoll: boolean,
    forkled: boolean,
    areThereRemainingDice: boolean,
    setSelectedDice: (selected: Array<boolean>) => void,
    isItMyTurn: () => boolean,
    roll: () => void,
    saveDice: () => void,
    endTurn: () => void,
}


function GameBody(props: GameBodyProps) {
    return (
        <div className="GameBody">
            {props.isItMyTurn() 
                ? <p>It's your turn, {props.players[props.currentTurn]}!</p>
                : <p>{props.players[props.currentTurn]} is playing</p> 
            }
            <Dice 
                dice={props.dice} 
                selected={props.selectedDice} 
                canPlayerRoll={props.canPlayerRoll} 
                forkled={props.forkled} 
                areThereRemainingDice={props.areThereRemainingDice} 
                setSelected={props.setSelectedDice} 
            />
            <span>
                <button className={props.canPlayerRoll && !props.forkled ? "StyledButton" : "DisabledButton"} onClick={props.roll} disabled={!props.canPlayerRoll || props.forkled}>
                    Roll
                </button>&nbsp;
                {props.dice.length > 0 && 
                    <button className={!props.canPlayerRoll && !props.forkled ? "StyledButton" : "DisabledButton"} onClick={props.saveDice} disabled={props.canPlayerRoll || props.forkled}>
                        Save selected dice
                    </button>
                }&nbsp;
                <button className="StyledButton" onClick={props.endTurn}>
                    End turn
                </button>&nbsp;
                {props.forkled && 
                    <p><b>You forkled!</b></p>
                }
                <p>Points scored this turn: {props.forkled ? 0 : props.turnScore}</p>
            </span>
        </div>
    )
}

export default GameBody;