export interface MessageResponse {
    type: string
    game_id?: String
}

export interface MessageRequest {
    type: string
    game_id?: string
}

export interface CreateGameRequest extends MessageRequest {
    // name: string
}

export interface CreateGameReponse extends MessageResponse {
    game_id: string
}

export interface JoinGameRequest extends MessageRequest {
}

export interface JoinGameReponse extends MessageResponse {
    game_id: string
}

export interface SendMoveRequest extends MessageRequest {
    move: string
    dice_nums?: Array<Number>
}

export const MESSAGE_TYPES = {
    CreateGame: "CreateGame",
    JoinGame: "JoinGame"
}

export enum EventType {
    Init = "init",
    Join = "join",
    Roll = "roll",
    SaveDice = "save_dice",
    StartGame = "start_game",
    EndTurn = "end_turn",
    Error = "error",
    Win = "win",
    Disconnect = "disconnect",
    Remove = "remove"
}

export interface Response {
    type: EventType,
    name: string,
    message: string,
    joinKey?: string,
    allPlayers?: Array<string>,
    remainingDice?: Array<string>,
    currentTurn?: number,
    roll?: Array<number>,
    savedIndices?: Array<number>,
    totalPoints?: number,
    turnPoints?: number,
    forkled?: boolean,
    error?: string
}

export const MOVE_TYPES = {
    Roll: "Roll",
    SaveDice: "SaveDice",
    EndTurn: "EndTurn"
}

export type InputEvent = React.ChangeEvent<HTMLInputElement>
export type ButtonEvent = React.MouseEvent<HTMLButtonElement>;