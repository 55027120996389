import {
    BsDice1,
    BsDice2,
    BsDice3,
    BsDice4,
    BsDice5,
    BsDice6
} from 'react-icons/bs';

import { IconContext } from "react-icons";
import '../App.css';

interface DiceProps {
    dice: Array<number>,
    selected: Array<boolean>,
    canPlayerRoll: boolean,
    forkled: boolean,
    areThereRemainingDice: boolean,
    setSelected: (selected: Array<boolean>) => void,
}

const DiceList = [<BsDice1/>, <BsDice2/>,<BsDice3/>, <BsDice4/>, <BsDice5/>, <BsDice6/>]

function Dice(props: DiceProps) {

    const selectDie = (index: number) => {
        let selections = [...props.selected];
        selections[index] = !selections[index]
        props.setSelected(selections)
    }

    let diceClass = props.areThereRemainingDice ? "Die DiceRemain" : "Die"

    return (
        <span className="DiceSvg">
            <IconContext.Provider value={{className: diceClass}}>
                {props.dice.map((num, index) => 
                    <span 
                        key={index}
                        className={props.forkled ? "ForkledDie" : props.selected[index] ? "SelectedDie" : ""}
                        onClick={() => {if (props.canPlayerRoll === false && props.forkled === false) selectDie(index);}}
                    >
                        {DiceList[num-1]}
                    </span>
                )}
            </IconContext.Provider>
        </span>
    )
}

export default Dice;