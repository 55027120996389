import {InputEvent} from '../types'

interface MenuProps {
    joinKey: string,
    setJoinKey: (joinKey: string) => void,
    createGame: () => void,
    joinGame: () => void,
}

function Menu(props: MenuProps) {

    const onChangeJoinKey = (event: InputEvent) => props.setJoinKey(event.target.value);

    return (
        <div className="GameBody">
            <button className="StyledButton" onClick={props.createGame}>Create new game</button>
            <br/>
            <p>Or</p>
            <br/>
            <input type="text" className="NameInput" value={props.joinKey} onChange={onChangeJoinKey} placeholder="Enter Join Key" maxLength={4}/>
            <br/>
            <button className="StyledButton" onClick={props.joinGame}>Join game</button>
        </div>
    )
}

export default Menu;