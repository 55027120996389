import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Game from './components/Game';
import {InputEvent} from './types';

function App() {

  const [showGame, setShowGame] = useState(false)
  const [name, setName] = useState('')
  const [showName, setShowName] = useState(false)

  const enterGame = () => {
    setShowName(true)
    setShowGame(true)
  }

  const onNameChange = (event: InputEvent) => setName(event.target.value);

  return (
    <div className="App">
      <ul className="NavBar">
        <li className="NavBarItem">Forkle</li>
        {showName && <li className="NavBarItem">{name}</li>}
      </ul>
      <div className="App-Body">
        {!showGame && <>
          <img src={logo} className="logo" alt="logo"/>
          <form onSubmit={enterGame}>
            <input className="NameInput" type="text" value={name} onChange={onNameChange} placeholder="Enter your name" maxLength={20} autoFocus/>
            <br/>
            <button type="button" className="StyledButton" onClick={enterGame}>Enter Game</button>
          </form>
        </>}
        {showGame && 
          <Game name={name}/>
        }
        </div>
    </div>
  );
}

export default App;
