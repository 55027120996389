import '../App.css';

interface ScoreboardProps {
    players: Array<string>,
    turn: number,
    scores: Array<number>
}

function Scoreboard(props: ScoreboardProps) {

    return (
        <div className="Scoreboard">
            <table className="ScoreTable">
                <thead>
                    <tr>
                        <th colSpan={2}>Players</th>
                    </tr>
                </thead>
                <tbody>
                    {props.players.map((player: string, index: number) => 
                        <tr key={player}>
                            <td>{props.turn === index ? <span className="CurrentPlayer">{player}</span> : player}</td>
                            <td>{props.scores[index] ?? 0}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default Scoreboard;