import { platform } from 'os';
import '../App.css';

interface PlayerListProps {
    players: Array<string>
}

function PlayerList(props: PlayerListProps) {
    return (
        <div className="PlayerList">
            <ol>    
                <li><b>Players</b>&nbsp;({props.players.length}/6)</li>
                {props.players.map((player: string, index: number) => 
                    <li key={player}>
                        {player}
                    </li>
                )}
            </ol>
        </div>
    )
}

export default PlayerList;