import PlayerList from "./PlayerList"

interface LobbyProps {
    players: string[],
    joinKey: string,
    startGame: () => void
}

function Lobby(props: LobbyProps) {
    return (
        <div className="Lobby">
            <PlayerList players={props.players}/>
            <button className="StyledButton" onClick={() => {props.startGame()}}>Start game</button>
            <p>Join key: {props.joinKey}</p>
        </div>
    )
}

export default Lobby;